/**
 * Exception identifier (code).
 */
export enum ExceptionIdentifier {
    // From JSONError
    /** 500: Server error. */
    UNKNOWN = "UNKNOWN",
    /** 403: Not started. */
    INTERACTION_UNKNOWN = "INTERACTION_UNKNOWN",

    // Generic fetch and HTTP status errors.
    /** 400: Bad request. */
    HTTP_400 = "HTTP_400",
    /** 401: Unauthorized. */
    HTTP_401 = "HTTP_401",
    /** 403: Forbidden. */
    HTTP_403 = "HTTP_403",
    /** 404: Not found. */
    HTTP_404 = "HTTP_404",
    /** 409: Conflict/Mismatch. */
    HTTP_409 = "HTTP_409",
    /** 422: Unprocessable. */
    HTTP_422 = "HTTP_422",
    /** 429: Too many requests. */
    HTTP_429 = "HTTP_429",
    /** 500: Server error. */
    HTTP_500 = "HTTP_500",
    /** 502: Bad gateway. */
    HTTP_502 = "HTTP_502",
    /** 503: Service unavailable. */
    HTTP_503 = "HTTP_503",
    /** 504: Gateway timeout. */
    HTTP_504 = "HTTP_504",

    /** Fetch exception: TypeError. */
    NETWORK_ERROR = "NETWORK_ERROR",
    /** Fetch exception: DOMException / AbortError. */
    ABORT_ERROR = "ABORT_ERROR",
    /** JSON parse error: SyntaxError. */
    JSON_ERROR = "JSON_ERROR",

    /* Flow errors. */
    /**
     * Any of the inputs that form credentials for the user are invalid,
     * the frontend might show a specific error detailing that login cannot be completed
     * due to invalid credentials and ofter to try again.
     */
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
    /**
     * Used when there is only one input, or we don't know which input specifically it is
     * (see INVALID_CREDENTIALS). Frontend should make all fields red/invalid and ask
     * to try again/fix errors.
     */
    INVALID_INPUT = "INVALID_INPUT",
    /**
     * The user has gotten into an invalid state and is being redirected back
     * to a state in which they can fix the issue.
     */
    INVALID_STATE = "INVALID_STATE",
    /**
     * The user is not yet authenticated in any way,
     * they need to complete a challenge to authenticate.
     */
    AUTHENTICATION_REQUIRED = "AUTHN_REQUIRED",

    /** No interaction ID: custom, local error */
    NO_INTERACTION_ID = "NO_INTERACTION_ID",

    /**
     *  Screen to show the user the account has been changed or created.
     */
    CONFIRMATION = "CONFIRMATION",

    /**
     * Specific errors used in the Error redirects
     */

    /**
     * JSONError was thrown on a non JSON route, should be replaced soon
     * In the frontend, this is just a generic error.
     */
    LEGACY_JSON_ERROR_THROWN = "GENERIC_ERROR",

    /**
     * Triggered when OIDC or SAML cannot redirect back to the client,
     * for instance if redirect URI or client ID is invalid.
     *
     * Alert the user that they should contact the app they came from.
     *
     * Example translation: "The application you attempted sign-in for is misconfigured. Please contact the helpdesk."
     */
    PROTOCOL_MISCONFIGURED_CLIENT = "PROTOCOL_MISCONFIGURED_CLIENT",

    /**
     * Invalid software state in the protocol finalize,
     * likely due to invalid sessions, missing user or software errors.
     *
     * Show to the user that they should restart the flow.
     *
     * Example translation: "Your sign-in failed unexpectedly, please log in again in a few moments."
     */
    PROTOCOL_FINALIZE_FAILURE = "PROTOCOL_FINALIZE_FAILURE",

    /**
     * Triggered when sign-in failed, for instance on SAML or OIDC.
     * Can possibly be resolved by trying again later.
     *
     * Example translation:
     * "Your sign-in failed. Please try again later. If this persists, please contact the helpdesk."
     */
    LOGIN_FAILURE = "LOGIN_FAILURE",

    /**
     * Triggered when a logout does not succeed.
     *
     * Example translation:
     * "Your sign-out failed. Please try again later. If this persists, please contact the helpdesk."
     */
    LOGOUT_FAILURE = "LOGOUT_FAILURE",

    /**
     * Triggered when an auth source login does not succeed.
     *
     * Example translation:
     * "Your sign-in at an external provider failed. Please try again later.
     * If this persists, please contact the helpdesk."
     */
    AUTH_SOURCE_LOGIN_FAILURE = "AUTH_SOURCE_LOGIN_FAILURE",

    /**
     * Triggered when an auth source login does not succeed due to user abort, or no sign-in.
     *
     * Example translation:
     * "You cancelled sign-in at the external provider. You can go back to the app overview and try again."
     */
    AUTH_SOURCE_USER_ABORTED = "AUTH_SOURCE_USER_ABORTED",

    /**
     * Triggered when an auth source login does not succeed due to other OIDC returned error.
     *
     * Example translation:
     * "The external provider is likely misconfigured, please contact our helpdesk for assistance."
     */
    AUTH_SOURCE_MISCONFIGURED = "AUTH_SOURCE_MISCONFIGURED",

    /**
     * Triggered when logging into a Service Provider (SAML SP) if we do not have an identifier
     *
     * Example translation:
     * "You cannot sign in to this third party, as you do not have an account there.
     * Please contact the helpdesk for assistance if this is a mistake."
     */
    SP_NO_IDENTIFIER = "SP_NO_IDENTIFIER",

    /**
     * Triggered when a user is finalizing a SAML login with a user that doesn't have a connection to google
     *
     * Example translation:
     * normal: "You cannot sign in to Google, as you do not have an account there.
     * Please contact the helpdesk for assistance if this is a mistake."
     * chromebook: "You cannot sign in with a chromebook, as your school has not connected your account to Google.
     * Please contact your schools account administrator to let him know of the issue.
     */
    USER_CONNECTION_GOOGLE_MISSING = "USER_CONNECTION_GOOGLE_MISSING",

    /**
     * The password of the user is expired.
     */
    PASSWORD_RESET_REQUIRED = "PASSWORD_RESET_REQUIRED",

    /**
     * The password of the user has been expired due to malicious actions with the password and has to be reset.
     */
    UNSAFE_PASSWORD_RESET_REQUIRED = "UNSAFE_PASSWORD_RESET_REQUIRED",
    /**
     * Lets the frontend know that a captcha challenge is required
     */
    CAPTCHA_REQUIRED = "CAPTCHA_REQUIRED",
    /**
     * The captcha was invalid (it can be retried)
     */
    CAPTCHA_INVALID = "CAPTCHA_INVALID",
    /**
     * Triggered when saml was not configured for the client.
     *
     * Example translation:
     * "SAML client was not configured correctly. Please contact the helpdesk for assistance."
     */
    INVALID_SAML_CLIENT = "INVALID_SAML_CLIENT",

    /**
     * The accesslist has blocked the current user
     */
    ACCESSLIST_DENIED = "ACCESSLIST_DENIED",

    /**
     * Something went wrong with retrieving the required data to handle
     * any future actions involving an auth source.
     */
    AUTH_SOURCE_CONNECTION_ISSUE = "AUTH_SOURCE_CONNECTION_ISSUE",
    /**
     * A licence is required to access this application.
     * Please contact Prowise sales to purchase additional licences.
     */
    NO_LICENCE = "NO_LICENCE",
    /**
     * You don’t have access to this application.
     * Please contact the Prowise helpdesk.
     */
    NO_ACCESS = "NO_ACCESS",
    /**
     * A licence is expiring.
     * Contact Prowise sales to extend your licences.
     */
    EXPIRING = "EXPIRING",

    /**
     * The data received from the frontend does not correspond with the data known to the backend.
     */
    INCONSISTENT_DATA = "INCONSISTENT_DATA",
    /**
     * The user didn't consent with the terms of use.
     */
    TERMS_OF_USE_DENIED = "TERMS_OF_USE_DENIED",
    /**
     * The server failed to register the user.
     */
    FAILED_USER_REGISTRATION = "FAILED_USER_REGISTRATION",
    /**
     * The server failed to link the third party to the account.
     */
    FAILED_ACCOUNT_LINK = "FAILED_ACCOUNT_LINK",
}

export enum ErrorSeverity {
    NONE,
    DEBUG,
    INFO,
    WARNING,
    ERROR,
    FATAL,
}

import { useBeforeLeave, useMatch, useNavigate, useSearchParams } from "@solidjs/router";
import { t } from "i18next";
import {
    Match,
    Suspense,
    Switch,
    type Component,
    createEffect,
    createSignal,
    lazy,
    type ParentComponent,
    ErrorBoundary,
} from "solid-js";
import { Loader } from "src/components/Loader";
import { Error } from "./Error";
import { config } from "../config/index.js";
import {
    StageType,
    type StageIdentifier,
    type FinalizeStage,
    type ChallengeInterface,
    BasicCredentialsStageIdentifier,
    MultiFactorStageIdentifier,
    ProviderSelectionStageIdentifier,
    PictureLoginStageIdentifier,
    UserLicenceStageIdentifier,
    QrCodeStageIdentifier,
    AccountManagementIdentifier,
    AccountMemoryIdentifier,
    ErrorSeverity,
    InfoIdentifiers,
} from "../domain";
import { type RedirectInterface } from "../domain/models/RedirectInterface";
import { useStageData } from "../hooks";
import { getStageErrorSeverity, matchStageType } from "../utilities";

const FinalizeScreen = lazy(async () => import("../challenges/FinalizeScreen"));

// Set of all implemented state screens
export const stateScreens: Record<StageIdentifier, string> = {
    [BasicCredentialsStageIdentifier.BASIC_CREDENTIALS]: "/challenges/credentials-basic",
    [BasicCredentialsStageIdentifier.PASSWORD]: "/challenges/credentials-login",
    [BasicCredentialsStageIdentifier.DOCUMENT_ACCEPT]: "/challenges/document-accept",
    [BasicCredentialsStageIdentifier.PASSWORD_EXPIRED]: "/challenge/password-reset-required",

    [QrCodeStageIdentifier.SCAN_QR]: "/challenges/other-device",
    [QrCodeStageIdentifier.VERIFY_LOGIN]: "/challenges/other-device-confirmation",

    [AccountMemoryIdentifier.PICK_REMEMBER_USER]: "/challenges/account-memory",

    [AccountManagementIdentifier.ACCOUNT_UNKNOWN]: "/challenges/account-unknown",
    [AccountManagementIdentifier.ACCOUNT_LINK]: "/challenges/account-linking",
    [AccountManagementIdentifier.ACCOUNT_COMPLETE]: "/challenges/account-complete",
    [AccountManagementIdentifier.EXTERNAL_EMAIL_VERIFICATION]: "/challenges/external-email-verification",
    [AccountManagementIdentifier.EXTERNAL_ACCOUNT_LINKING]: "/challenges/external-account-linking",
    [AccountManagementIdentifier.ACCOUNT_LINK_CONFIRMATION]: "/challenges/account-link-confirmation",
    [AccountManagementIdentifier.EXTERNAL_ACCOUNT_REGISTRATION]: "/challenges/external-account-registration",
    [AccountManagementIdentifier.EXTERNAL_ACCOUNT_REGISTRATION_OR_LINKING]: "/challenges/external-account-registration-or-linking",

    [MultiFactorStageIdentifier.MFA_TOTP]: "/challenges/mfa-totp",
    [MultiFactorStageIdentifier.REGISTER_TOTP]: "/challenges/mfa-totp",
    [MultiFactorStageIdentifier.MFA_PIN]: "/challenges/mfa-totp", // () => <>Not implemented</>,
    [MultiFactorStageIdentifier.REGISTER_PIN]: "/challenges/mfa-totp", // () => <>Not implemented</>,
    [ProviderSelectionStageIdentifier.SELECT_AUTH_SOURCE]: "/challenges/select",
    [ProviderSelectionStageIdentifier.SELECT_EXTERNAL_AUTH_SCHOOL]: "/challenges/region-select",
    [PictureLoginStageIdentifier.SELECT_SCHOOL]: "/challenges/picture-select",
    [PictureLoginStageIdentifier.SELECT_GROUP]: "/challenges/picture-select/:school_id?",
    [PictureLoginStageIdentifier.SELECT_USER]: "/challenges/picture-select/:school_id?/:group_id?",
    [PictureLoginStageIdentifier.PICTURE_LOGIN]: "/challenges/picture-login",

    [UserLicenceStageIdentifier.NO_ACCESS]: "/challenges/user-licence",
    [UserLicenceStageIdentifier.NO_LICENCE]: "/challenges/user-licence",
    [UserLicenceStageIdentifier.LICENCE_EXPIRING]: "/challenges/user-licence",

    [InfoIdentifiers.CONFIRMATION]: "/challenges/confirmation",

    /* [ExternalAuthProviderStateStageIdentifier.GOOGLE]: "/challenges/select",
    [ExternalAuthProviderStateStageIdentifier.APPLE]: "/challenges/select",
    [ExternalAuthProviderStateStageIdentifier.MICROSOFT]: "/challenges/select",
    [ExternalAuthProviderStateStageIdentifier.VIDIS]: "/challenges/region-select",
    [ExternalAuthProviderStateStageIdentifier.LEER_ID]: "/challenges/region-select",
    [ExternalAuthProviderStateStageIdentifier.VLAAMSE_OVERHEID]: "/challenges/region-select",
    [ExternalAuthProviderStateStageIdentifier.KLAS_CEMENT]: "/challenges/region-select", */
};

export const SessionInitializer: Component = () => {
    const { setRequestData } = useStageData();

    // Trigger initial request
    setRequestData({});
    return <Loader dialog>
        {t("general.title.initializing")}
    </Loader>;
};

export const StateRenderer: ParentComponent = (props) => {
    const [params] = useSearchParams<{ response_type: string; redirectUrl: string }>();
    const navigate = useNavigate();
    const { stageData, refetch, requestData, setRequestData } = useStageData();

    // Store a local authentication identifier based on the response and (previous) request.
    // This way, we can render the appropriate screen, even if the response was an error.
    const [identifier, setIdentifier] = createSignal<StageIdentifier | undefined>();

    window.addEventListener("popstate", () => {
        setRequestData({});
    });

    createEffect(() => {
        // Update identifier to match latest stageData with fallback to requestData
        setIdentifier(
            (
                matchStageType<ChallengeInterface>(stageData.latest, StageType.CHALLENGE) &&
                stageData.latest?.identifier
            ) ||
            requestData()?.identifier,
        );
    });

    createEffect(() => {
        const idPath = identifier();
        if (idPath) {
            sessionStorage.removeItem("externalSchoolRegion");
            let stateScreenPath = stateScreens[idPath];
            // When there are mock responses keep all payloads and mockStatuses as a query param.
            // Note that originally, this was done in useDevelopmentParams
            if (config.debug.mockResponse) {
                const url = new URL(window.location.href);
                const payloads = url.searchParams.getAll("payload");
                const mockStatuses = url.searchParams.getAll("mockStatus");
                stateScreenPath += `?payload=${payloads.join("&payload=")}&mockStatus=${mockStatuses.join("&mockStatus=")}`;
            }
            navigate(stateScreenPath);
        }
    });

    createEffect(() => {
        if (matchStageType<RedirectInterface>(stageData.latest, StageType.REDIRECT)) {
            // Due to some weird behaviour with the back button and state management on the select challenge
            //  we have to navigate to a separate page that redirects us further, instead of redirecting directly
            sessionStorage.setItem("redirect", (stageData.latest).url);
            navigate("/redirect");
        }
    });

    createEffect(() => {
        // Preferably we would set the URL in Upvoty to /protocols/upvoty/auth/
        // but this is here to ensure backwards compatibility
        if (params.response_type === "upvoty") {
            window.location.replace(`/protocols/upvoty/auth/?redirectUrl=${params.redirectUrl}`);
        }
    });

    useBeforeLeave((e) => {
        if (useMatch(() => e.to.toString())()) {
            e.preventDefault();
        }
    });

    const [isInError, setInError] = createSignal(false);
    createEffect((previousInterval: undefined | ReturnType<typeof setInterval>) => {
        if (previousInterval || isInError()) {
            clearInterval(previousInterval);
            return undefined;
        }

        // Try to refetch every 5 minutes when there is no interaction
        return setInterval(refetch, 5 * 60 * 1000);
    });

    return (
        <Suspense fallback={<Loader dialog delay/>}>
            <ErrorBoundary fallback={(err) => {
                // Stop the keep alive when an error is shown
                if (!isInError()) {
                    setInError(true);
                }
                return <Error stageData={stageData.latest} exception={err} />;
            }}>
                {/* Note that all stageData uses the `latest` property
                        to prevent Suspense from kicking in and flashing the interface */}
                <Switch fallback={
                    <>{(() => {
                        // Stop the keep alive when an error is shown
                        if (!isInError()) {
                            setInError(true);
                        }
                        return <Error stageData={stageData.latest}/>;
                    })()}</>
                }>
                    <Match when={stageData.latest === undefined && !stageData.loading}>
                        {/* Initialize session */}
                        <SessionInitializer />
                    </Match>
                    <Match when={matchStageType<FinalizeStage>(stageData.latest, StageType.FINALIZE)}>
                        {/* Finalize stage */}
                        <FinalizeScreen/>
                    </Match>
                    {/* Here come the identifiers (both from stage response as well as request with error response) */}
                    <Match when={getStageErrorSeverity(stageData.latest) <= ErrorSeverity.WARNING &&
                        identifier() &&
                        // @ts-expect-error - We've just checked this.
                        identifier() in stateScreens}>
                        {/* eslint-disable-next-line @typescript-eslint/no-confusing-void-expression */}
                        {(() => {
                            if (isInError()) {
                                setInError(false);
                            }
                            return undefined;
                        })()}
                        {props.children}
                    </Match>
                </Switch>
            </ErrorBoundary>
        </Suspense>
    );
};

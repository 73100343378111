import styled from "@suid/system/styled";
import { type StyledProps } from "@suid/system/styledProps";
import { type GridProps } from ".";

/** StyledGridProps */
type StyledGridProps = Pick<GridProps, "desktop" | "mobile" | "landscape" | "tileSize">;

export const StyledGrid = styled("div", {
    name: "grid",
    skipProps: ["desktop", "mobile", "sx"],
})<StyledGridProps>(
    (params) => {
        const props = params.props as GridProps;
        const { theme } = params;

        // TODO: landscape property
        const tileSize = props.tileSize ?? "88px";

        const styles: StyledProps = {
            "alignContent": "start",
            "display": "grid",
            "overflowX": "hidden",
            "overflowY": "auto",

            "gridGap": theme.spacing(1) as StyledProps,
            "justifyContent": "center", // space-evenly
            "gridTemplateColumns": `repeat(${props.desktop}, ${tileSize})`,
            "minWidth": props.width,

            // Mobile width
            "@media (max-width: 872px)": {
                gridTemplateColumns: `repeat(${props.mobile}, ${tileSize})`,
                minWidth: "auto",
                maxWidth: `calc(100% - ${theme.spacing(3)})`,
                width: props.width,
            },
            // Tiny width
            "@media (max-width: 516px)": {
                // Smaller labels
                "& .MuiTypography-caption": {
                    fontSize: "0.65rem",
                },
                "gridTemplateColumns": `repeat(${props.mobile}, minmax(auto, ${tileSize}))`,
                "width": "auto",
            },
        };

        return styles;
    },
);

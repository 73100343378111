export enum BasicCredentialsStageIdentifier {
    BASIC_CREDENTIALS = "BasicCredentials",
    PASSWORD = "Password",
    DOCUMENT_ACCEPT = "DocumentAccept",
    PASSWORD_EXPIRED = "PasswordResetRequired",
}

export enum MultiFactorStageIdentifier {
    MFA_TOTP = "MultiFactorAuthenticateTotp",
    REGISTER_TOTP = "MultiFactorRegisterTotp",
    MFA_PIN = "MultiFactorAuthenticatePin",
    REGISTER_PIN = "MultiFactorRegisterPin",
}

export enum QrCodeStageIdentifier {
    SCAN_QR = "QRCode",
    VERIFY_LOGIN = "SecondDeviceConfirmation",
}

export enum AccountManagementIdentifier {
    ACCOUNT_UNKNOWN = "AccountUnknown",
    ACCOUNT_LINK = "AccountLink",
    ACCOUNT_LINK_CONFIRMATION = "AccountLinkConfirmation",
    ACCOUNT_COMPLETE = "AccountComplete",
    EXTERNAL_EMAIL_VERIFICATION = "ExternalEmailVerify",
    EXTERNAL_ACCOUNT_LINKING = "ExternalAccountLinking",
    EXTERNAL_ACCOUNT_REGISTRATION = "ExternalAccountRegistration",
    EXTERNAL_ACCOUNT_REGISTRATION_OR_LINKING = "ExternalAccountRegistrationOrLinking",
}

export enum ProviderSelectionStageIdentifier {
    SELECT_EXTERNAL_AUTH_SCHOOL = "SelectExternalAuthSchool",
    SELECT_AUTH_SOURCE = "SelectAuthSource",
}

export enum PictureLoginStageIdentifier {
    SELECT_SCHOOL = "SelectSchool",
    SELECT_GROUP = "SelectGroup",
    SELECT_USER = "SelectUser",
    PICTURE_LOGIN = "PictureLogin",
}

export enum UserLicenceStageIdentifier {
    NO_ACCESS = "NoAccess",
    NO_LICENCE = "NoLicence",
    LICENCE_EXPIRING = "LicenceExpiring",
}

export enum AccountMemoryIdentifier {
    PICK_REMEMBER_USER = "PickRememberUser",
}

export enum InfoIdentifiers {
    CONFIRMATION = "Confirmation",
}

/** Stage identifier type. */
export type StageIdentifier =
    BasicCredentialsStageIdentifier |
    MultiFactorStageIdentifier |
    QrCodeStageIdentifier |
    AccountManagementIdentifier |
    ProviderSelectionStageIdentifier |
    PictureLoginStageIdentifier |
    AccountMemoryIdentifier |
    UserLicenceStageIdentifier |
    InfoIdentifiers;
